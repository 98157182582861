.tokyo_tm_services {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 100px 0px;
}

.tokyo_tm_services .list {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}

.tokyo_tm_services .list ul {
  margin: 0px 0px 0px -40px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.tokyo_tm_services .list ul li {
  margin: 0px 0px 40px 0px;
  width: 33.3333%;
  padding-left: 40px;
}

.tokyo_tm_services .list ul li .list_inner {
  width: 100%;
  height: 100%;
  clear: both;
  float: left;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 45px 30px 40px 30px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.tokyo_tm_services .list ul li .list_inner:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.tokyo_tm_services .list ul li .number {
  display: inline-block;
  margin-bottom: 25px;
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  font-weight: 700;
  color: #000;
  font-family: "Montserrat";

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.tokyo_tm_services .list ul li .list_inner:hover .number {
  background-color: rgba(0, 0, 0, 0.08);
}

.tokyo_tm_services .list ul li .title {
  font-weight: 700;
  color: #000;
  font-size: 18px;
  margin-bottom: 15px;
}

.tokyo_tm_services .list ul li .tokyo_tm_read_more {
  margin-top: 7px;
}

.tokyo_tm_services .list ul li .list_inner:hover .tokyo_tm_read_more a:before {
  animation: read-more-anim-2;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.6, 0.01, 0, 1);
}

.tokyo_tm_services .list ul li .list_inner:hover .tokyo_tm_read_more a span {
  transform: translateX(0%);
}

.tokyo_tm_services .popup_service_image {
  opacity: 0;
  visibility: hidden;
  display: none;
  position: absolute;
  z-index: -111;
}

.tokyo_tm_services .service_hidden_details {
  opacity: 0;
  visibility: hidden;
  display: none;
  position: absolute;
  z-index: -111;
}

.tokyo_tm_modalbox .service_popup_informations {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}

.tokyo_tm_modalbox .service_popup_informations .image {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 37px;
}

.tokyo_tm_modalbox .service_popup_informations .image img {
  position: relative;
  min-width: 100%;
  opacity: 0;
}

.tokyo_tm_modalbox .service_popup_informations .image .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tokyo_tm_modalbox .service_popup_informations .main_title {
  width: 100%;
  float: left;
  margin-bottom: 23px;
}

.tokyo_tm_modalbox .service_popup_informations .main_title h3 {
  font-size: 23px;
  font-weight: 600;
}

.tokyo_tm_modalbox .service_popup_informations .descriptions {
  width: 100%;
  float: left;
}

.tokyo_tm_modalbox .service_popup_informations .descriptions p {
  margin-bottom: 15px;
}

.tokyo_tm_modalbox .service_popup_informations .descriptions p:last-child {
  margin-bottom: 0px;
}

.tokyo_tm_partners {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #fff;
  padding: 100px 0px;
}

.tokyo_tm_partners .partners_inner {
  width: 100%;
  float: left;
  clear: both;
  overflow: hidden;
  border: 2px solid #eee;
}

.tokyo_tm_partners ul {
  margin: -2px -10px -2px -2px;
  list-style-type: none;
  padding-top: 2px;
  float: left;
  padding-left: 2px;
  min-width: calc(100% + 12px);
}

.tokyo_tm_partners ul li {
  margin: 0px;
  float: left;
  width: 25%;
  border: 2px solid #eee;
  text-align: center;
  height: 145px;
  line-height: 145px;
  position: relative;
  margin-top: -2px;
  margin-left: -2px;
  overflow: hidden;
}

.tokyo_tm_partners ul li img {
  max-width: 50%;
  max-height: 100px;
}

.tokyo_tm_partners ul li .list_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  opacity: 0.5;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.tokyo_tm_partners ul li .list_inner:hover {
  opacity: 1;
}

.tokyo_tm_pricing {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 60px 0px;
  background-color: #fff;
}

.tokyo_tm_pricing .tokyo_tm_button a {
  padding: 6px 40px 11px 40px;
}

.tokyo_tm_pricing .list {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}

.tokyo_tm_pricing .list > ul {
  margin: 0px 0px 0px -40px;
  list-style-type: none;
}

.tokyo_tm_pricing .list > ul > li {
  margin: 0px 0px 40px 0px;
  padding-left: 40px;
  width: 33.3333%;
  float: left;
}

.tokyo_tm_pricing .list > ul > li .list_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 27px 40px 45px;
}

.tokyo_tm_pricing .price {
  width: 100%;
  float: left;
}

.tokyo_tm_pricing .price h3 {
  font-size: 40px;
  font-weight: 600;
}

.tokyo_tm_pricing .price span {
  position: relative;
  display: inline-block;
}

.tokyo_tm_pricing .price .currency {
  position: absolute !important;
  bottom: 10px;
  right: -10px;
  font-size: 15px;
}

.tokyo_tm_pricing .plan {
  width: 100%;
  float: left;
}

.tokyo_tm_pricing .plan h3 {
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.tokyo_tm_pricing .item {
  margin: 0px;
  list-style-type: none;
}

.tokyo_tm_pricing .item li {
  margin: 0px;
  opacity: 0.5;
}

.tokyo_tm_pricing .item li.active {
  opacity: 1;
}

.tokyo_tm_pricing .tokyo_tm_button {
  margin-top: 30px;
}

.tokyo_tm_pricing .popular {
  position: absolute;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  font-size: 13px;
  padding: 0 20px 3px;
  top: -17px;
  right: 10px;
}

.tokyo_tm_facts {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 60px 0px;
}

.tokyo_tm_facts .list {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}

.tokyo_tm_facts .list ul {
  margin: 0px 0px 0px -40px;
  list-style-type: none;
}

.tokyo_tm_facts .list ul li {
  margin: 0px 0px 40px 0px;
  padding-left: 40px;
  width: 33.333%;
  float: left;
}

.tokyo_tm_facts .list ul li .list_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px 20px;
}

.tokyo_tm_facts .list h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 3px;
}
